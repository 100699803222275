// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// constants
import { SUBSCRIPTION_ACTIONS, COUPON_ACTIONS } from 'state-manager/constants'

// state-manager
import { AppDispatch, history } from 'state-manager/store'

// routes
import routes from 'routes'
import { fetchSubscription } from 'state-manager/reducers/subscription'
import { fetchBillingInfo } from 'state-manager/reducers/billing-info'

export const cancelSubscription = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    url: 'users/current/subscription/cancel',
  })
    .then(() => {
      dispatch(fetchSubscription())
      notification.success('Subscription successfully canceled!')
    })
    .finally(() => {
      dispatch({ type: SUBSCRIPTION_ACTIONS.SUBSCRIPTION_CANCEL.SYSTEM })
    })
}

export const setCurrentSubscription = (data: Record<string, any>, redirectTo = null) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    url: 'users/current/subscription',
    data,
  })
    .then(() => {
      dispatch(fetchSubscription())
        .then(() => {
          notification.success('Successfully subscribed!')

          const toPage = redirectTo ?? routes.dashboardClients
          history.push(toPage)

          if (data.coupon) {
            dispatch({ type: COUPON_ACTIONS.COUPON_GET_INFO.SYSTEM })
          }
        })

    })
    .finally(() => {
      dispatch({ type: SUBSCRIPTION_ACTIONS.SUBSCRIPTION_SET_CURRENT.SYSTEM })
    })
}

export const addPaymentMethod = (data: Record<string, any>, location) => (dispatch: AppDispatch) => {
  reqH({
    method: 'PUT',
    url: 'users/current/stripe/billing-info',
    data,
  })
    .then(() => {
      notification.success('Payment method successfully added!')
      dispatch(fetchBillingInfo())
        .then(() => {
          if (location.state?.formData) {
            dispatch(setCurrentSubscription(location.state.formData, routes.addDataSource))
          } else {
            // In case of absent subscription automatically redirects to subscriptions page. Handled by authorized accessor. @venseon
            history.push(routes.invoices)
          }
        })
    })
    .finally(() => {
      dispatch({ type: SUBSCRIPTION_ACTIONS.PAYMENT_METHOD_ADD.SYSTEM })
    })
}

export const updateDefaultPaymentMethod = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'PUT',
    url: 'users/current/stripe/payment-methods/default',
    data,
  })
    .then(() => {
      notification.success('Default payment method successfully updated!')
      dispatch(fetchBillingInfo())
      dispatch(fetchSubscription()) //needed to calculate VAT
    })
}
