import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import reqH from 'utils/request-handler'

type subscriptionType = {
  status: 'trialing' | 'active' | 'canceled'
  maximumMonthlyMediaSpend: string | null
  currentMonthlyMediaSpend: string | null
  currency: number | null
  renewsAt: string // string in UTC format
  cancelsAt: string | null // string in UTC format
  plan: {
    id: string | null
    plan: string | null
    monthlyMediaSpend: number | null
    period: string | null
    price: number | null
    currency: number | null
  } | null
  hasCustomPlanRequest: boolean,
  changePlanLink: string | null,
  tax: {
    amount: number | null,
    percentage: number | null,
  } | null
}

export interface ISubscription {
  data: subscriptionType & {
    isSubscriptionCanceled: boolean
    isOnTrial: boolean
    isSubscriptionActive: boolean
  }
  isLoaded: boolean
}

export const fetchSubscription = createAsyncThunk(
  'subscription/fetch',
  async () => {
    const res = await reqH<{ result: subscriptionType }>({
      method: 'GET',
      url: 'users/current/subscription',
      // shouldUpdateUserData: true,
    })

    // return {
    //   status: 'canceled',
    //   maximumMonthlyMediaSpend: '10000',
    //   currentMonthlyMediaSpend: '10000',
    //   currency: 3,
    //   renewsAt: "2024-04-26T10:22:29.000000Z",
    //   cancelsAt: "2024-04-26T10:22:29.000000Z",
    //   plan: {
    //     id: '145',
    //     plan: null,
    //     monthlyMediaSpend: 25000,
    //     period: 'year',
    //     price: 75840,
    //     currency: null,
    //   },
    //   hasCustomPlanRequest: false,
    //   changePlanLink: null
    // }

    return res.data.result
  }
)

const initialState: ISubscription = {
  data: {
    plan: null,
    maximumMonthlyMediaSpend: null,
    currentMonthlyMediaSpend: null,
    currency: null,
    // endsAt: null,
    hasCustomPlanRequest: false,

    // dateSubscriptionCanceled: null,
    isSubscriptionCanceled: false,
    isOnTrial: false,
  },
  isLoaded: false,
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubscription.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
        isOnTrial: action.payload.status === 'trialing',
        isSubscriptionCanceled: Boolean(action.payload.cancelsAt),
        isSubscriptionActive: action.payload.status === 'active'
      }
      state.isLoaded = true
    })
    builder.addCase(fetchSubscription.rejected, (state) => {
      state.isLoaded = true
    })
  }
})

export default subscriptionSlice.reducer
